import React from "react";
import img1 from "../Assets/Images/why-ico1.svg";
import img2 from "../Assets/Images/why-ico2.svg";
import img3 from "../Assets/Images/why-ico3.svg";
import img4 from "../Assets/Images/why-col.png";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
const Whychooseus = () => {
  return (
    <div>
      <section className='why-us-sec'>
        <div className='container'>
          <div className='why-us-title'>
            <ScrollAnimation animateIn='fadeIn'>
              <h2>Beat the Digital Struggle, Partner with Teqnite!</h2>
            </ScrollAnimation>
            <div className='why-us-para'>
              <ScrollAnimation animateIn='fadeIn'>
                <p>
                  Tired of feeling lost in the ever-changing digital world? No
                  need to worry anymore! Teqnite's got your back with a team of
                  passionate pros ready to help you dominate. From stunning
                  websites to eye-catching graphics, our cutting-edge solutions
                  go beyond conventional marketing.
                </p>
                <br />
                <p>
                  We align your goals with our digital superpowers to ensure you
                  crush them online. Forget the struggle; join the smooth
                  journey to success with Teqnite.
                </p>
              </ScrollAnimation>
            </div>
          </div>
          <div className='why-us-row'>
            <div className='why-us-c1'>
              <ScrollAnimation animateIn='fadeIn'>
                <div className='why-us-cell' style={{ paddingTop: "0" }}>
                  <div className='why-cell-ico'>
                    <img src={img1} alt='' />
                  </div>
                  <div className='why-cell-cntnt'>
                    <h3>Proven Industry Experience</h3>
                    <p>
                      With extensive field experience, we bring seasoned
                      expertise to every project we deliver. Our experts with a
                      track record of success apply a depth of knowledge and
                      proficiency from years of hands-on experience.
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeIn'>
                <div className='why-us-cell'>
                  <div className='why-cell-ico'>
                    <img src={img2} alt='' />
                  </div>
                  <div className='why-cell-cntnt'>
                    <h3>High-Performance Service Portfolio</h3>
                    <p>
                      We're a leading internet marketing agency offering
                      competitive services to worldwide businesses. You can,
                      too, benefit from our proven expertise and unmatched
                      efficacy in every project.
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeIn'>
                <div className='why-us-cell' style={{ borderBottom: "none" }}>
                  <div className='why-cell-ico'>
                    <img src={img3} alt='' />
                  </div>
                  <div className='why-cell-cntnt'>
                    <h3>Resourcefulness</h3>
                    <p>
                      We have abundant digital resources — our key to innovative
                      solutions and effective strategies for all your digital
                      marketing needs.
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className='why-us-c2'>
              <ScrollAnimation animateIn='fadeIn'>
                <img src={img4} alt='' />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Whychooseus;
