import React from "react";
import { Link } from "react-router-dom";

const OurOffice = () => {
  return (
    <div>
      <section className='office-sec'>
        <div className='container'>
          <div className='office-sec-title'>
            <h2>Our Offices</h2>
          </div>
          <div className='office-sec-row'>
            {/* <div className='office-block'>
              <div className='office-ifr'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3065.506939948235!2d-75.4666675!3d39.7956192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6e3884af3c6ed%3A0x2cc8babaadea1501!2s2803%20Philadelphia%20Pike%20B%2C%20Claymont%2C%20DE%2019703%2C%20USA!5e0!3m2!1sen!2s!4v1703000708830!5m2!1sen!2s'
                  style={{ border: "0px" }}
                  loading='lazy'
                  referrerPolicy='no-referrer-when-downgrade'
                ></iframe>
              </div>
              <div className='office-cntnt'>
                <h3>United States</h3>
                <h4>Registered Office</h4>
                <ul>
                  <li>
                    <span>Location:</span>
                    <Link
                      to='https://maps.app.goo.gl/t4tgCtZfEdUzzgru5'
                      target='__blank'
                    >
                      2803 Philadelphia Pike STE B 479 Claymont DE 19703
                    </Link>
                  </li>
                  <li>
                    <span>Email:</span>
                    <Link to='mailto:sales@teqnite.com'>sales@teqnite.com</Link>
                  </li>
                  <li>
                    <span>Phone:</span>
                    <Link to='tel:+1619798 8070'>+1 619 798 8070</Link>
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <div className="office-block">
              <div className="office-ifr">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.6594998271075!2d-0.12071470000000002!3d51.5194626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b343249578f%3A0x4711a970799b62ca!2s230%20Old%20Gloucester%20St%2C%20London%20WC1X%208TN%2C%20UK!5e0!3m2!1sen!2s!4v1708192519395!5m2!1sen!2s"
                  style={{ border: "0px" }}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="office-cntnt">
                <h3>United Kingdom</h3>
                <h4>Registered Office</h4>
                <ul>
                  <li>
                    <span>Location:</span>
                    <Link
                      to="https://maps.app.goo.gl/uoQ4447FfLEk1A4XA"
                      target="__blank"
                    >
                      AA House, 230-27 Old Gloucester Street, London
                    </Link>
                  </li>
                  <li>
                    <span>Email:</span>
                    <Link to="mailto:sales@teqnite.com">sales@teqnite.com</Link>
                  </li>
                  <li>
                    <span>Phone:</span>
                    <Link to="tel:0226119490">022 6119490</Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className='office-block'>
              <div className='office-ifr'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3604.8498487237216!2d68.3360594!3d25.3763483!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x394c7083e3d259df%3A0xb9589371bdac8a1b!2sDawood%20Super%20Market%2C%20Auto%20Bahn%20Rd!5e0!3m2!1sen!2s!4v1711377967113!5m2!1sen!2s'
                  style={{ border: "0px" }}
                  loading='lazy'
                  referrerPolicy='no-referrer-when-downgrade'
                ></iframe>
              </div>
              <div className='office-cntnt'>
                <h3>Pakistan</h3>
                <h4>Registered Office</h4>
                <ul>
                  <li>
                    <span>Location:</span>
                    <Link
                      to='https://maps.app.goo.gl/NqRZg3aKnMxL4QYu8'
                      target='__blank'
                    >
                      Office #205, 7th Floor, Dawood Center, Auto Bahn Rd,
                      Hyd,Sindh
                    </Link>
                  </li>
                  <li>
                    <span>Email:</span>
                    <Link to='mailto:sales@teqnite.com'>sales@teqnite.com</Link>
                  </li>
                  <li>
                    <span>Phone:</span>
                    <Link to='tel:0226119490'>022 6119490</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurOffice;
